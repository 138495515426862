import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { TableHeader } from 'components/table/table-content';
import { PaginationHandler } from 'elements/pagination-handler';
import { MooringArticleListItem, getArticleDimensionStringShort } from 'models';
import { MooringArticleCategoryService, MooringArticleService } from 'services';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { Router } from 'aurelia-router';
import { DataFilter } from 'lib/tables/DataFilter';
import { PubSub } from 'lib/event/PubSub';
import { getCallbackData } from 'elements/filterer';
import { Filters, filterTypes } from 'elements/Filter';
import { DialogService } from 'aurelia-dialog';
import { MooringArticleDialog } from './mooring-article-dialog';

type TableFilter = {
  categoryId?: number;
} & DataFilter;
type MooringArticleListItemWithDimension = MooringArticleListItem & { Dimension: string };
@autoinject()
export class MooringArticleContainer {
  protected articles: MooringArticleListItemWithDimension[] = [];
  protected dataFilter: TableFilter = new DataFilter();

  protected drawerName = 'mooring-article';

  protected tableHeaders: TableHeader<
    MooringArticleListItem & { actions: ''; Dimension: string; IsLocked: boolean }
  >[] = [
    { key: 'IsLocked', label: '', sortable: false },
    { key: 'Id', label: 'general.id', sortable: true },
    { key: 'Category', label: 'general.category', sortable: true },
    { key: 'Type', label: 'general.type', sortable: true },
    { key: 'ProductType', label: 'general.product', sortable: true },
    { key: 'Dimension', label: 'general.dimension', sortable: false },
    { key: 'Supplier', label: 'general.supplier', sortable: true },
    { key: 'MBL', label: 'general.mbl', sortable: true },
    { key: 'actions', label: 'general.actions', sortable: false },
  ];

  constructor(
    private errorService: ErrorService,
    private mooringArticleCategoryService: MooringArticleCategoryService,
    private articleService: MooringArticleService,
    private toast: ToastService,
    private router: Router,
    private pagination: PaginationHandler,
    private pubsub: PubSub,
    private t: I18N,
    private dialogService: DialogService
  ) {}

  protected setupPagination() {
    const routeParams = this.router.currentInstruction?.queryParams;
    let currentPage = 1;
    if (routeParams && routeParams.currentPage) {
      currentPage = +routeParams.currentPage;
    }

    this.pubsub.sub('filter-section:search-text-changed', (data) => {
      if (data.context !== this.context) return;
      this.dataFilter.searchText = data.searchText;
      void this.getArticles();
    });

    this.pubsub.sub('filter-section:list-settings-changed', (data) => {
      if (this.dataFilter.top === data.settings.pageSize) return;

      this.dataFilter.top = data.settings.pageSize;
      this.pagination.pageSize = data.settings.pageSize;
      this.pagination.currentPage = 1;
      void this.getArticles();
    })

    this.pubsub.sub('filter-section:reset-filters', (data) => {
      if (data.context !== this.context) return;
      this.filterClearAll();
    });

    this.pubsub.sub('list-entity:updated', () => {
      void this.getArticles();
    });

    this.pubsub.sub('list-entity:created', () => {
      void this.getArticles();
    });
    // this.pubsub.sub('export-list', (data) => {
    //   if (data === 'mooring-article-register') {
    //     void this.getArticles({ export: true });
    //   }
    // });

    this.pagination.init({
      currentPage: currentPage,
      totalItems: 0,
      onPageChanged: (_, pagination) => {
        this.dataFilter.skip = pagination.skip;
        this.dataFilter.top = pagination.top;
        void this.getArticles();
      },
    });
  }

  protected openArticleForm(id?: number) {
    console.log('openArticleForm', id);
    void this.dialogService
      .open({
        viewModel: MooringArticleDialog,
        model: id ? { articleId: id } : undefined,
        position: () => {
          return {
            top: 0,
          };
        },
      })
      .whenClosed(() => {
        document.querySelector('html').style.overflowY = null;
      });
  }

  protected async onDelete(id: number) {
    try {
      if (id) {
        await this.mooringArticleCategoryService.delete(id);
        this.toast.showSuccess(this.t.tr('general.deleted'));
        await this.getArticles();
      }
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  protected attached() {
    this.setupPagination();
    void this.getArticles();
  }

  protected detached() {
    this.pubsub.unsub();
  }

  protected setOrderByColumn(column: string) {
    this.dataFilter.setOrderByAndFlipOrderDirectionIfSameKey(column);
    void this.getArticles();
  }

  protected context = 'mooring_articles';
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected filterGetFiltersQuery: () => Promise<{ serviceStationId?: number; customerIds?: string[] }>;
  protected setFilterValueByName: (name: string, data: any) => void;

  protected defaultFilters = {
    serviceStationId: true,
    // customerIds: true,
  };

  protected getFilterKey() {
    return 'MOORING_ARTICLES_TABLE';
  }

  protected onFilterChanged() {
    void this.getArticles();
  }

  getFilterValues() {
    return getCallbackData(this.filterGetFiltersQuery);
  }

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(async () => {
        const filters: Filters = {
          serviceStationId: {
            name: 'category',
            label: this.t.tr('general.category'),
            type: filterTypes.RADIO,
            options: [{ Id: 1, Name: 'e' }], //filteredServiceStations.map((x) => ({ Id: x.Id, Name: x.Name })),
          },
        };
        res(filters);
      });
    });
  }

  protected async getArticles(options?: { export: boolean }) {
    try {
      // const filters = await this.filterGetFiltersQuery?.();
      // this.dataFilter.export = props?.export || false;
      // this.dataFilter.categoryId = filters?.categoryId || null;
      // this.dataFilter.locale = this.i18n.getLocale() as 'en' | 'nb' | 'es';

      const response = await this.articleService.table(this.dataFilter);
      if (!response) return;
      this.pagination.totalItems = +response.headers.get('x-total-count') || 0;
      this.articles = response.data.map((x) => {
        (x as MooringArticleListItemWithDimension)['Dimension'] = getArticleDimensionStringShort(x);
        return x as MooringArticleListItemWithDimension;
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }
}
